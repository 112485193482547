
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import {mdiDotsVertical, mdiDownload} from "@mdi/js";
import {ListDataSource} from "@/data/List/ListDataSource";
import {Filter} from "@/cms-services/filter";

@Component
export default class Home extends Vue {
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "QuoteSource",
  });
  

  icons: any = {
    dotsVertical: mdiDotsVertical,
    download:mdiDownload
  };

  get caption(){
    return this.dataSource.model?.caption;
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Валюты",
        to: "/manage/quotations/",
        exact: true,
      },
      {
        text: this.caption,
        disabled: true,
      },
    ];
  }

}
